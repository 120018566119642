import React from "react";

import classes from "./Popup.module.css";

const Popup = (props) => {
  return !props.showPopup ? null : (
    <section className={classes.MainContainer}>
      <div className={classes.Overlay} onClick={props.hidePopup}></div>
      <div className={classes.ContentWrapper}>
        <div className={classes.CloseIcon} onClick={props.hidePopup}>
          <i className="fas fa-times"></i>
        </div>
        {props.children}
      </div>
    </section>
  );
};

export default Popup;
