import React from "react";

import classes from "./InsuranceStepSection.module.css";

const InsuranceStepSection = () => {
  const stepItem = (stepCount, stepTitle, stepDesc) => {
    return (
      <div className={classes.StepItem}>
        <div>
          <h3 className={classes.StepLabel}>{stepCount + ". " + stepTitle}</h3>
          <p className={classes.StepDesc}>{stepDesc}</p>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.MainContainer}>
      {/* <h2 className={classes.MainHeading}>Easy Three Steps.</h2> */}
      <div className={classes.StepsWrapper}>
        {stepItem(
          "01",
          "Fill Form",
          "Share your requirement with us and we will connect with you within 24 hours."
        )}
        {stepItem(
          "02",
          "Receive Multiple Quotes",
          "We find the best offers from multiple insurance companies."
        )}
        {stepItem(
          "03",
          "Get Insured",
          "Once you find the best offer, you can apply right away."
        )}
      </div>
    </div>
  );
};

export default InsuranceStepSection;
