import React from "react";
import { Link } from "react-router-dom";
import { useScrollPosition } from "../../../hooks/useScrollPosition";

import classes from "./Topbar.module.css";

const Topbar = (props) => {
  const scrollPosition = useScrollPosition();

  return (
    <div
      className={[
        classes.MainContainer,
        scrollPosition > 0 ? classes.TopbarShadow : null,
      ].join(" ")}
    >
      <div className={classes.Logo}>
        <span>Yojas</span>
        <br />
        Solutions
      </div>
      <nav>
        <Link
          to="#"
          className={classes.MenuItem}
          onClick={props.scrollToProducts}
        >
          Services
        </Link>
        <Link
          to="#"
          className={classes.MenuItem}
          onClick={props.scrollToReviewSection}
        >
          Reviews
        </Link>

        <button className={classes.BtnGetQuote} onClick={props.scrollToQuote}>
          Get Quote
        </button>
      </nav>
    </div>
  );
};

export default Topbar;
