import React from "react";
import { BrowserRouter } from "react-router-dom";

import Homepage from "./containers/Homepage/Homepage";

import classes from "./App.module.css";
import Footer from "./components/molecules/Footer/Footer";

function App() {
  return (
    <BrowserRouter>
      <div className={classes.MainContainer}>
        <Homepage />
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
