import TextField from "@mui/material/TextField";

import classes from "./InputBox.module.css";

const InputBox = ({ name, placeholder }) => {
  return (
    <div className={classes.TextFieldWrapper}>
      <TextField
        required
        label={placeholder}
        name={name}
        className={classes.InputBox}
      />
    </div>
  );
};

export default InputBox;
