import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { InputLabel } from "@mui/material";

import classes from "./DropdownBox.module.css";

const DropdownBox = ({ name, label, options }) => {
  const renderOptions =
    options &&
    options.map((item, pos) => (
      <MenuItem value={item.value}>{item.label}</MenuItem>
    ));

  return (
    <div className={classes.DropdownBoxWrapper}>
      <FormControl fullWidth className={classes.DropdownBox}>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label={label}
          name={name}
        >
          {renderOptions}
        </Select>
      </FormControl>
    </div>
  );
};

export default DropdownBox;
