import React from "react";

import VikramPic from "../../../../assets/vikram-1.png";
import VedantPic from "../../../../assets/vedant.png";
import classes from "./TestimonialSection.module.css";
import CarouselCustom from "../../../../components/molecules/CarouselCustom/CarouselCustom";

const TestimonialSection = () => {
  return (
    <section className={classes.MainContainer}>
      <h2 className={classes.MainHeading}>Testimonials</h2>

      <div className={classes.TestimonialsWrapper}>
        <div className={classes.BannerCarousel}>
          <CarouselCustom>
            <div className={classes.TestimonialItem}>
              <div className={classes.LeftSection}>
                <h3 className={classes.TestimonialText}>
                  Amazing services provided by the team. They helped me with a
                  firm insurance. Got the best service!
                </h3>
                <p className={classes.TestimonialUser}>Vikram Kalia</p>
              </div>

              <div className={classes.RightSection}>
                <img className={classes.TestimonialUserImage} src={VikramPic} />
              </div>
            </div>
            <div className={classes.TestimonialItem}>
              <div className={classes.LeftSection}>
                <h3 className={classes.TestimonialText}>
                  I was looking for car insurance. Team shared multiple options
                  and helped me choose the right one.
                </h3>
                <p className={classes.TestimonialUser}>Vedant Kalia</p>
              </div>

              <div className={classes.RightSection}>
                <img className={classes.TestimonialUserImage} src={VedantPic} />
              </div>
            </div>
          </CarouselCustom>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
