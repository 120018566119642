import axios from "axios";
import React from "react";
import DarkButton from "../../../../components/atoms/DarkButton/DarkButton";
import DropdownBox from "../../../../components/atoms/DropdownBox/DropdownBox";
import InputBox from "../../../../components/atoms/InputBox/InputBox";
import Popup from "../../../../components/molecules/Popup/Popup";
import InsuranceStepSection from "../InsuranceStepSection/InsuranceStepSection";

import classes from "./ApplyNowSection.module.css";

const ApplyNowSection = () => {
  const intialState = { showPopup: false };
  const [state, setState] = React.useState({ ...intialState });

  const onFormSubmit = (e) => {
    e.preventDefault();
    const fullName = e.target.fullName.value;
    let firstName = "";
    let lastName = e.target.fullName.value;
    if (fullName.split(" ").length > 1) {
      firstName = fullName.split(" ")[0];
      lastName = fullName.split(" ").slice(1).join(" ");
    }
    let formData = new FormData();
    formData.append(
      "xnQsjsdp",
      "f4accba967bb60aaadcbc35e7a6b6b0106851754525c87a753938833ea4a55d1"
    );
    formData.append(
      "xmIwtLD",
      "2afab83f74074f23469fd4a1f204a9c0013a1c6ce9937fa12ab0edd164c97c8e"
    );
    formData.append("actionType", "TGVhZHM=");
    formData.append("First Name", firstName);
    formData.append("Last Name", lastName);
    formData.append("Email", e.target.email.value);
    formData.append("Phone", "+91" + e.target.contactNumber.value);
    formData.append("LEADCF1", e.target.LEADCF1.value);

    axios
      .post("https://crm.zoho.in/crm/WebToLeadForm", formData)
      .then((response) => {
        setState({ ...state, showPopup: true });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const hidePopup = () => {
    setState({ ...state, showPopup: false });
  };

  return (
    <section className={classes.MainContainer}>
      <h2 className={classes.MainHeading}>Easy Three Steps</h2>
      <div className={classes.SectionWrapper}>
        <div className={classes.StepWrapper}>
          <InsuranceStepSection />
        </div>
        <div className={classes.FormWrapper}>
          <h2 className={classes.FormHeading}>Request Quote</h2>
          <form
            onSubmit={onFormSubmit}
            name="WebToLeads455913000000258556"
            method="POST"
          >
            <InputBox placeholder={"Full Name"} name={"fullName"} />
            <InputBox placeholder={"Email Address"} name={"email"} />
            <InputBox placeholder={"Contact Number"} name={"contactNumber"} />
            <DropdownBox
              name={"LEADCF1"}
              label={"Select Insurance Type"}
              options={[
                {
                  value: "Cyber&#x20;Security&#x20;Insurance",
                  label: "Cyber Security Insurance",
                },
                {
                  value: "Group&#x20;Health&#x20;Insurance",
                  label: "Group Health Insurance",
                },
                {
                  value: "Group&#x20;Personal&#x20;Accident&#x20;insurance",
                  label: "Group Personal Accident insurance",
                },
                {
                  value: "Group&#x20;Term&#x20;Plan&#x20;Insurance",
                  label: "Group Term Plan Insurance",
                },
                {
                  value: "Health&#x20;Insurance",
                  label: "Health Insurance",
                },
                {
                  value:
                    "Life&#x20;Insurance&#x20;Saving&#x20;and&#x20;Endowment&#x20;Plan",
                  label: "Life Insurance Saving and Endowment Plan",
                },
                {
                  value: "Marine&#x20;Cargo&#x20;Insurance",
                  label: "Marine Cargo Insurance",
                },
                {
                  value: "Mutual&#x20;Fund&#x20;Investment",
                  label: "Mutual Fund Investment",
                },
                {
                  value: "Pure&#x20;Term&#x20;Plan",
                  label: "Pure Term Plan",
                },
                {
                  value: "Private&#x20;and&#x20;Commercial&#x20;Vehicles",
                  label: "Private and Commercial Vehicles",
                },
                {
                  value:
                    "Standard&#x20;Fire&#x20;and&#x20;Special&#x20;Perils&#x20;Insurance",
                  label: "Standard Fire and Special Perils Insurance",
                },
                {
                  value: "Workmen&#x20;Compensation&#x20;Insurance",
                  label: "Workmen Compensation Insurance",
                },
              ]}
            />
            <div className={classes.ButtonWrapper}>
              <DarkButton
                fullWidth
                label="Request Quote"
                icon={<i className="fas fa-arrow-right"></i>}
              />
            </div>
          </form>
        </div>
      </div>
      <Popup showPopup={state.showPopup} hidePopup={hidePopup}>
        <div>
          <h2 className={classes.PopupText}>Request Successful!</h2>
          <p className={classes.PopupDesc}>
            Our team will get in touch with you within 24 hours. We look forward
            to connect with you.
          </p>
        </div>
      </Popup>
    </section>
  );
};

export default ApplyNowSection;
