import React from "react";

import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={classes.Footer}>
      <div>
        <div className={classes.LoveText}>
          <span>Made with</span>
          <i class="fas fa-heart"></i>
          <span>in India</span>
        </div>
      </div>

      <div className={classes.ContactInfo}>
        <i class="fas fa-phone"></i> +91 8076916262
      </div>
    </footer>
  );
};

export default Footer;
