import React from "react";
import IconHome from "../../../../assets/ic_home.png";
import IconHealth from "../../../../assets/ic_health.png";
import IconTravel from "../../../../assets/ic_travel.png";
import IconCar from "../../../../assets/ic_car.png";

import classes from "./ProductSection.module.css";
import ServiceItem from "../ServiceItem/ServiceItem";

const ProductSection = () => {
  const servicesProvided = {
    motor: [
      {
        id: 1,
        label: "Car",
        icon: "fas fa-car",
      },
      {
        id: 2,
        label: "Bike",
        icon: "fas fa-motorcycle",
      },
      {
        id: 3,
        label: "School Bus",
        icon: "fas fa-bus-alt",
      },
      {
        id: 4,
        label: "Taxi/Cab",
        icon: "fas fa-taxi",
      },
      {
        id: 5,
        label: "Truck",
        icon: "fas fa-truck-moving",
      },
    ],
    health: [
      {
        id: 1,
        label: "Medical",
        icon: "fas fa-briefcase-medical",
      },
      {
        id: 2,
        label: "Top up Plan",
        icon: "fas fa-user-plus",
      },
      {
        id: 3,
        label: "Port Health Insurance",
        icon: "fas fa-hand-holding-medical",
      },
      {
        id: 4,
        label: "Employee Health/Group Health",
        icon: "fas fa-walking",
      },
      {
        id: 5,
        label: "OPD Health Insurance",
        icon: "fas fa-notes-medical",
      },
    ],
    business: [
      {
        id: 1,
        label: "Standard Fire & Special Perils Insurance",
        icon: "fas fa-fire",
      },
      {
        id: 2,
        label: "Marine Cargo Insurance",
        icon: "fas fa-truck-loading",
      },
      {
        id: 3,
        label: "Workmen Compensation Insurance",
        icon: "fas fa-hard-hat",
      },
      {
        id: 4,
        label: "Commercial General Insurance",
        icon: "fas fa-stethoscope",
      },
      {
        id: 5,
        label: "Public Liability Insurance",
        icon: "fas fa-truck-moving",
      },
    ],
    others: [
      {
        id: 1,
        label: "International Travel",
        icon: "fas fa-plane-departure",
      },
      {
        id: 2,
        label: "Cyber Security Insurance",
        icon: "fas fa-robot",
      },
      {
        id: 3,
        label: "Director & Officer Liability Policy",
        icon: "fas fa-user-md",
      },
      {
        id: 4,
        label: "Group Term Plan",
        icon: "fas fa-users",
      },
      {
        id: 5,
        label: "Shop",
        icon: "fas fa-store-alt",
      },
    ],
  };
  return (
    <section className={classes.MainContainer}>
      <h2 className={classes.MainHeading}>Available Services</h2>

      <section>
        <p className={classes.ServiceCategory}>{"Motor Insurance"}</p>
        <div className={classes.ServiceGrid}>
          {servicesProvided.motor.map((item) => (
            <ServiceItem
              key={item.id}
              iconClass={item.icon}
              label={item.label}
            />
          ))}
        </div>
      </section>
      <section>
        <p className={classes.ServiceCategory}>{"Health Insurance"}</p>
        <div className={classes.ServiceGrid}>
          {servicesProvided.health.map((item) => (
            <ServiceItem
              key={item.id}
              iconClass={item.icon}
              label={item.label}
            />
          ))}
        </div>
      </section>
      <section>
        <p className={classes.ServiceCategory}>{"Business Insurance"}</p>
        <div className={classes.ServiceGrid}>
          {servicesProvided.business.map((item) => (
            <ServiceItem
              key={item.id}
              iconClass={item.icon}
              label={item.label}
            />
          ))}
        </div>
      </section>
      <section>
        <p className={classes.ServiceCategory}>{"Others"}</p>
        <div className={classes.ServiceGrid}>
          {servicesProvided.others.map((item) => (
            <ServiceItem
              key={item.id}
              iconClass={item.icon}
              label={item.label}
            />
          ))}
        </div>
      </section>
      {/* <div className={classes.RightSection}>
        <div className={classes.InsuranceTypeWrapper}>
          <div className={classes.InsuranceTypeItem}>
            <div
              className={classes.IconWrapper}
              style={{ backgroundColor: "#DEDBF0" }}
            >
              <img
                className={classes.InsuranceTypeIcon}
                src={IconHome}
                alt="Home Icon"
              />
            </div>
            <div>
              <h3 className={classes.InsuranceTypeLabel}>Home</h3>
              <p className={classes.InsuranceTypeDesc}>
                Protect your household items and more.
              </p>
            </div>
          </div>
          <div className={classes.InsuranceTypeItem}>
            <div
              className={classes.IconWrapper}
              style={{ backgroundColor: "#C8F4F2" }}
            >
              <img
                className={classes.InsuranceTypeIcon}
                src={IconHealth}
                alt="Health Icon"
              />
            </div>
            <div>
              <h3 className={classes.InsuranceTypeLabel}>Health</h3>
              <p className={classes.InsuranceTypeDesc}>
                Hospital stays, treatments and more.
              </p>
            </div>
          </div>
          <div className={classes.InsuranceTypeItem}>
            <div
              className={classes.IconWrapper}
              style={{ backgroundColor: "#F4D8BF" }}
            >
              <img
                className={classes.InsuranceTypeIcon}
                src={IconTravel}
                alt="Travel Icon"
              />
            </div>
            <div>
              <h3 className={classes.InsuranceTypeLabel}>Travel</h3>
              <p className={classes.InsuranceTypeDesc}>
                Stay safe and relaxed in your vacations.
              </p>
            </div>
          </div>
          <div className={classes.InsuranceTypeItem}>
            <div
              className={classes.IconWrapper}
              style={{ backgroundColor: "#C8E0F3" }}
            >
              <img
                className={classes.InsuranceTypeIcon}
                src={IconCar}
                alt="Car Icon"
              />
            </div>
            <div>
              <h3 className={classes.InsuranceTypeLabel}>Automobile</h3>
              <p className={classes.InsuranceTypeDesc}>
                Protection against theft and damage.
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default ProductSection;
