import React from "react";

import classes from "./DarkButton.module.css";

const DarkButton = (props) => {
  const classNames = [classes.Button];
  if (props.fullWidth) {
    classNames.push(classes.FullWidth);
  }
  return (
    <button className={classNames.join(" ")} onClick={props.click}>
      {props.label}
      <span className={classes.ButtonIcon}>{props.icon}</span>
    </button>
  );
};

export default DarkButton;
