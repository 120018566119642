import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import classes from './CarouselCustom.module.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


const CarouselCustom = (props) => {
    const initialState = {
        currentActive: 0,
    }
    const [state, setState] = React.useState({...initialState});

    const onSlideChange = (newPos) => {
        setState({...initialState, currentActive: newPos});
    }

    const renderIndicators = props.children.map((item, pos) => {
        return <div key={pos} className={[classes.CarouselIndicator, pos === state.currentActive ? classes.Active : null].join(' ')}></div>;
    })

    return(
        <div className={classes.BannerCarousel}>
            <Carousel
                onChange={onSlideChange}
                showArrows={false}
                showThumbs={false}
                showStatus={false}
                showIndicators={false}
                autoPlay={true}
                infiniteLoop={true}>
                    {props.children}
            </Carousel>
            <div className={classes.IndicatorWrapper}>
                {renderIndicators}
            </div>
        </div>
    );
}

export default CarouselCustom;