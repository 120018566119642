import classes from "./ServiceItem.module.css";

const ServiceItem = ({ iconClass, label }) => {
  return (
    <div className={classes.ServiceItem}>
      <i class={iconClass}></i>
      <p className={classes.ServiceName}>{label}</p>
    </div>
  );
};

export default ServiceItem;
