import React from "react";
import LandingSection from "./components/LandingSection/LandingSection";
import ProductSection from "./components/ProductSection/ProductSection";
import TestimonialSection from "./components/TestimonialSection/TestimonialSection";
import ApplyNowSection from "./components/ApplyNowSection/ApplyNowSection";
import Topbar from "../../components/molecules/Topbar/Topbar";

const Homepage = () => {
  const productRef = React.useRef(null);
  const reviewsRef = React.useRef(null);
  const quoteRef = React.useRef(null);

  const scrollToProducts = () => {
    productRef.current.scrollIntoView();
  };

  const scrollToReviewSection = () => {
    reviewsRef.current.scrollIntoView();
  };

  const scrollToQuote = () => {
    quoteRef.current.scrollIntoView();
  };

  return (
    <main>
      <Topbar
        scrollToProducts={scrollToProducts}
        scrollToReviewSection={scrollToReviewSection}
        scrollToQuote={scrollToQuote}
      />
      <LandingSection scrollToQuote={scrollToQuote} />
      <div ref={productRef}>
        <ProductSection />
      </div>
      <div ref={reviewsRef}>
        <TestimonialSection />
      </div>
      <div ref={quoteRef}>
        <ApplyNowSection />
      </div>
    </main>
  );
};

export default Homepage;
