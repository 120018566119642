import React from "react";
import DarkButton from "../../../../components/atoms/DarkButton/DarkButton";

import LandingSectionBg from "../../../../assets/landing-bg.png";
import CarIllustration from "../../../../assets/car-illustration.png";
import classes from "./LandingSection.module.css";

const LandingSection = (props) => {
  return (
    <section className={classes.MainContainer}>
      <div className={classes.LandingSectionBg}>
        <img src={LandingSectionBg} alt="Landing Background" />
      </div>

      <div className={classes.ContentWrapper}>
        <section className={classes.LeftSection}>
          <h1 className={classes.MainHeading}>
            Fast way to <br /> <span>Get Insured</span>
          </h1>

          <div>
            <div className={classes.USP}>
              <p>
                <i className="fas fa-circle"></i> Submit requests online 24x7
              </p>
            </div>
            <div className={classes.USP}>
              <p>
                <i className="fas fa-circle"></i> Fast and intuitive process
              </p>
            </div>
            <div className={classes.USP}>
              <p>
                <i className="fas fa-circle"></i> Quality and real quotes
              </p>
            </div>

            <div className={classes.ButtonWrapper}>
              <DarkButton
                label={"Get a Quote"}
                icon={<i className="fas fa-arrow-right"></i>}
                click={props.scrollToQuote}
              />
            </div>
          </div>
        </section>

        <section className={classes.RightSection}>
          <img
            className={classes.CarIllustration}
            src={CarIllustration}
            alt="Blue Car"
          />
        </section>
      </div>
    </section>
  );
};

export default LandingSection;
